import FeminaPlusLogo from '@/femina/public/image/femina-plus-logo.svg';
import { StandaloneComponent } from '@/types/component';
import {
  StandaloneArticleTeaserBodytext,
  StandaloneArticleTeaserBodytextProps,
} from 'base/components/ArticleTeaser/Bodytext';

export const FeminaStandaloneArticleTeaserBodytext: StandaloneComponent<StandaloneArticleTeaserBodytextProps> = (
  props,
) => {
  return <StandaloneArticleTeaserBodytext subscriptionLogo={<FeminaPlusLogo />} {...props} />;
};
